import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import { service1,service2,service3,service4,service5,service6,service7} from '../../assets/img'
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
function Ourservices() {
  return (
    <div className="our-service-section">
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-center aos-init mb-5" data-aos="zoom-in-up">
                        <h1 className="mb-2">Our Services</h1>
                    </div>
                </div>
            </div>
            <div className="our-service-inner aos-init" data-aos="zoom-in-up">
                
                <div className="our-service-wrapper">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="service-content">
                                <h3>Welcome to Madhinah Dry cleaning</h3>
                                <p className="show-more">Let us turn your laundry nightmare into a more pleasant experience with a range of quality laundry solutions. Whether you need help with your washing and ironing for your home or business we can offer a convenient and competitively priced service. Get your clothes looking crisp and fresh with our professional ironing service. To make your life even easier we can also pick up your dirty clothes and drop off your freshly washed, folded and ironed laundry. Did you know that the average family can spend up to 8-9 hours per week doing laundry? That’s a lot of time spent sorting, washing, drying, folding and ironing clothes! And that’s not to mention the “difficult” items such as sports gear or duvets. For fast, convenient laundry services contact us.</p>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <Swiper
                                spaceBetween={15}
                                navigation={false}
                                autoplay = {true}
                                modules={[Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 2,
                                    },
                                }}
                                className="hover-offer"
                            >
                                <SwiperSlide>
                                    <img src={service1} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service2} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service3} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service4} alt="services" />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img src={service5} alt="services" />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Ourservices;
